import DetailsHeader from 'app-sections/common/applications/details/components/details-header/DetailsHeader';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

export default function ApplicationPaymentLayout() {
  return (
    <>
      <Box mt={-3}>
        <DetailsHeader isWithTabs={false} isWithBreadcrumbs={false} isWithStateListener={false} />
      </Box>
      <Outlet />
    </>
  );
}
