import ApplicationStateListener from 'app-sections/common/applications/application-state-listener/ApplicationStateListener';

import { Box, Container } from '@mui/material';

import DetailsTabs from './components/details-tabs/DetailsTabs';
import DetailsTitle from './components/details-title/DetailsTitle';

export interface DetailsHeaderProps {
  isWithTabs?: boolean;
  isWithBreadcrumbs?: boolean;
  isWithStateListener?: boolean;
}

export default function DetailsHeader(props: DetailsHeaderProps) {
  const { isWithTabs = true, isWithBreadcrumbs = true, isWithStateListener = true } = props;
  return (
    <Box mt={5}>
      <Container className="disable-center">
        <DetailsTitle isWithBreadcrumbs={isWithBreadcrumbs} />
        {isWithStateListener && <ApplicationStateListener />}
        {isWithTabs && <DetailsTabs />}
      </Container>
    </Box>
  );
}
