import { useState } from 'react';
import { Params } from 'types';
import BasicSelect from 'ui/form-fields/basic-fields/select/BasicSelect';
import BasicTextInput from 'ui/form-fields/basic-fields/text-input/TextInput';

import { Box, Grid } from '@mui/material';

import {
  GENERAL_BUILDING_CODE_CATEGORY,
  SPECIFIC_BUILDING_CODE_CATEGORY_WITH_DESC,
  SPECIFIC_ZONING_CODE_CATEGORY_WITH_DESC,
  ZR16_USE_CATEGORY,
} from '../../categories-config/categories.const';
import {
  getSpecificBuildingCollectionByParentId,
  getSpecificZoningUseCollectionByParentId,
} from '../../categories-config/categories.helper';
import { FormValues } from '../../validation-schema';

const MAX_CHARACTERS = 200;

export interface PropertyUseCategoriesProps {
  control: any;
  setValue: any;
  data: FormValues;
  defaults?: FormValues;
}

export const getBuildingExtraFieldState = (categoryValue: string): boolean => {
  return SPECIFIC_BUILDING_CODE_CATEGORY_WITH_DESC.includes(categoryValue);
};

export const getZoningExtraFieldState = (categoryValue: string): boolean => {
  return SPECIFIC_ZONING_CODE_CATEGORY_WITH_DESC.includes(categoryValue);
};

export default function PropertyUseCategories(props: PropertyUseCategoriesProps) {
  const { control, setValue, data, defaults } = props;

  const [specificZoneCollection, setSpecificZoneCollection] = useState<Params[]>(
    getSpecificZoningUseCollectionByParentId(defaults?.zr16UseCategory || '')
  );
  const [specificBuildingCollection, setSpecificBuildingCollection] = useState<Params[]>(
    getSpecificBuildingCollectionByParentId(defaults?.generalBuildingCodeUseCategory || '')
  );

  // Categories logic
  const handleChangeGeneralCategory = (categoryId: string) => {
    setSpecificBuildingCollection(getSpecificBuildingCollectionByParentId(categoryId));
    setValue('specificBuildingCodeUse', '');
  };

  const handleChangeZR16Category = (categoryId: string) => {
    setSpecificZoneCollection(getSpecificZoningUseCollectionByParentId(categoryId));
    setValue('specificZoningUse', '');
  };

  const isBuildingExtraFieldVisible = getBuildingExtraFieldState(data.specificBuildingCodeUse);
  const isZoningExtraFieldVisible = getZoningExtraFieldState(data.zr16UseCategory);

  return (
    <Grid container columnSpacing={4} spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicSelect
          collection={GENERAL_BUILDING_CODE_CATEGORY}
          label="General Building Code Use Category"
          inputName="generalBuildingCodeUseCategory"
          control={control}
          disabled={false}
          onChange={(e) => {
            handleChangeGeneralCategory(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicSelect
          collection={specificBuildingCollection}
          label="Specific Building Code Use"
          inputName="specificBuildingCodeUse"
          control={control}
          disabled={data.generalBuildingCodeUseCategory === ''}
        />
        {isBuildingExtraFieldVisible && (
          <Box mt={3}>
            <BasicTextInput label="Specify Building Use" inputName="specificBuildingCodeDesc" control={control} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicSelect
          collection={ZR16_USE_CATEGORY}
          label="Zoning Use Category"
          inputName="zr16UseCategory"
          control={control}
          disabled={false}
          onChange={(e) => {
            handleChangeZR16Category(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicSelect
          collection={specificZoneCollection}
          label="Specific Zoning Use"
          inputName={!isZoningExtraFieldVisible ? 'specificZoningUse' : 'specificZoningUseDisabled'}
          control={control}
          disabled={data.zr16UseCategory === '' || isZoningExtraFieldVisible}
        />
        {isZoningExtraFieldVisible && (
          <Box mt={3}>
            <BasicTextInput
              label="Specify Zoning Use"
              inputName="specificZoningUse"
              control={control}
              inputProps={{ maxLength: MAX_CHARACTERS }}
              helperText={`${data.specificZoningUse.length || 0}/${MAX_CHARACTERS}`}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
