import { getCategoryByIdAndType } from 'app-sections/user/applications/steps/step4/components/property-use-form/categories-config/categories.const';
import {
  getBuildingExtraFieldState,
  getZoningExtraFieldState,
} from 'app-sections/user/applications/steps/step4/components/property-use-form/components/property-use-categories/PropertyUseCategories';
import { insertAtIndex } from 'helpers/insertAtIndes';
import { get } from 'lodash-es';
import { Params } from 'types';
import ChipList from 'ui/components/chip-list/ChipList';
import { SummaryTextGridItem } from 'ui/components/summary-text-grid/SummaryTextGrid';

import { Box } from '@mui/material';

export const getPropertyUseCategoriesInfo = (data: Params, isShort?: boolean): any[] => {
  const specificBuildingCodeUse = get(data, 'specificBuildingCodeUse') || '';

  const isBuildingExtraFieldVisible = getBuildingExtraFieldState(specificBuildingCodeUse);

  const zr16UseCategory = get(data, 'zr16UseCategory') || '';
  const specificZoningUse = get(data, 'specificZoningUse') || '';
  const isZoningExtraFieldVisible = getZoningExtraFieldState(zr16UseCategory);

  const insertPoint = isShort ? 0 : 2;

  let regularInfo: SummaryTextGridItem[] = [
    {
      label: 'General Building Code Use Category',
      desc: (
        <CategoryChip
          categoryId={get(data, 'generalBuildingCodeUseCategory') || ''}
          categoryType={'generalBuildingCodeUseCategory'}
        />
      ),
    },
    {
      label: 'Specific Building Code Use',
      desc: <CategoryChip categoryId={specificBuildingCodeUse} categoryType={'specificBuildingCodeUse'} />,
    },
    {
      label: 'Zoning Use Category',
      desc: <CategoryChip categoryId={zr16UseCategory} categoryType={'zr16UseCategory'} />,
    },
    {
      label: 'Specific Zoning Use',
      desc: isZoningExtraFieldVisible ? (
        specificZoningUse
      ) : (
        <CategoryChip categoryId={specificZoningUse} categoryType={'specificZoningUse'} />
      ),
    },
  ];

  if (isShort) {
    regularInfo = regularInfo.filter((v, i) => [1, 3].includes(i));
  }

  if (isBuildingExtraFieldVisible) {
    const newItem = {
      label: 'Specified Building Use',
      desc: get(data, 'specificBuildingCodeDesc') || '',
    };

    regularInfo = insertAtIndex<SummaryTextGridItem>(newItem, insertPoint, regularInfo);
  }

  return regularInfo;
};

export interface CategoryChipProps {
  categoryId: string;
  categoryType: string;
}
function CategoryChip(props: CategoryChipProps) {
  const { categoryId, categoryType } = props;
  if (!categoryId) {
    return null;
  }

  const categoryName = getCategoryByIdAndType(categoryId, categoryType)?.name || '';

  return (
    <Box my={-2.5}>
      <ChipList classNames="upper outlined-gray" list={[{ name: categoryName, id: categoryId }]} />
    </Box>
  );
}
