import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import AddressFormatted from 'ui/components/address-formatted/AddressFormatted';
import BreadCrumbs from 'ui/components/breadcrumbs/BreadCrumbs';
import { isAdmin } from 'ui/helpers/users';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ApplicationStateBadge from '../application-state-badge/ApplicationStateBadge';
import CheckSwoButton from '../check-swo-button/CheckSwoButton';
import DebugLink from '../debug-link/DebugLink';
import MarkAsPaidButton from '../mark-as-paid-button/MarkAsPaidButton';

export interface DetailsTitleProps {
  isWithBreadcrumbs: boolean;
}

export default function DetailsTitle(props: DetailsTitleProps) {
  const { activeApplicationId } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const { isWithBreadcrumbs } = props;

  const applicationQueryKey = ['applications', activeApplicationId];
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading, data } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  const applicationNumber = data?.certificatePermitNumber || data?.id;

  return (
    <Box>
      {currentUser && isWithBreadcrumbs && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
          <BreadCrumbs
            data={[
              {
                label: 'My Applications',
                link: `${isAdmin(currentUser.userProfile.roles) ? '/admin' : ''}/applications`,
              },
              {
                label: `Application Number ${applicationNumber}`,
                link: '',
              },
            ]}
          />
          <DebugLink />
          <CheckSwoButton />
          {data && <MarkAsPaidButton application={data} />}
        </Box>
      )}
      <Box mt={-1} mb={3} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box mr={4}>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator"
            sx={{ mt: 3, fontSize: matchesMobile ? '2.2rem' : '4rem' }}
          >
            Application {''}
            {!matchesMobile && <>Number </>}
            {matchesMobile && <>#</>}
            {applicationNumber}
            {!matchesMobile && data && (
              <Box display="block" sx={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
                <AddressFormatted address={data.propertyAddress} commaSeparated={true} isWithCity={true} />
              </Box>
            )}
          </Typography>
        </Box>
        {!isLoading && data && <ApplicationStateBadge appState={data.state} />}
      </Box>
    </Box>
  );
}
