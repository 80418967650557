import { Application, Params } from 'types';

export type CertificateStateVariantsId = 'validAfter' | 'validBefore' | 'noValidCoo';

export function getInitialVariants(): {
  [key in CertificateStateVariantsId]: Params;
} {
  return {
    validAfter: {
      metaData: {},
      optionId: '',
    },
    validBefore: { metaData: { isValid: true, data: {} }, optionId: 'change' },
    noValidCoo: { metaData: { isValid: true, data: {} }, optionId: '' },
  };
}

export function setVariantInitialData(data: Application): {
  [key in CertificateStateVariantsId]: Params;
} {
  let variants = getInitialVariants();
  if (data.existingCooType) {
    variants[data.existingCooType] = {
      metaData: {
        ...(data.existingCooType === 'validAfter' && {
          data: data.previousCoo,
          isValid: Boolean(data?.previousCoo?.customID),
        }),
        ...(data.existingCooType === 'validBefore' && {
          data: {},
          isValid: true,
        }),
        ...(data.existingCooType === 'noValidCoo' && {
          data: {},
          isValid: Boolean(data.typeOfApplication),
        }),
      },
      optionId: data.typeOfApplication,
    };
  }

  return variants;
}

export type VariantOptionId =
  | 'change'
  // | "revision"
  | 'newConstruction'
  | 'temporarySeasonal'
  | 'twoFamilyFlat';

export interface VariantOption {
  id: VariantOptionId;
  label: string;
  description: string | JSX.Element;
}

export const prepareDataToSave = (data: Params, variant: CertificateStateVariantsId): Params => {
  return {
    existingCooType: variant,
    typeOfApplication: data[variant].optionId,
    ...(variant === 'validAfter' && {
      previousCoo: data[variant].metaData?.data || null,
    }),
  };
};

export const getApplicationTypeLabelById = (id: VariantOptionId): string => {
  return VARIANTS.find((item) => item.id === id)?.name || '';
};

export const getApplicationTypeById = (id: CertificateStateVariantsId): string => {
  return TYPES.find((item) => item.id === id)?.name || '';
};

export const VARIANTS: { id: VariantOptionId; name: string }[] = [
  {
    id: 'change',
    name: 'Change/Revision',
  },
  {
    id: 'newConstruction',
    name: 'New Construction/ Establishment',
  },
  {
    id: 'temporarySeasonal',
    name: 'Temporary/ Seasonal',
  },
  {
    id: 'twoFamilyFlat',
    name: 'Two-family Flat',
  },
];

export const TYPES: { id: CertificateStateVariantsId; name: string }[] = [
  {
    id: 'validAfter',
    name: 'There is a valid Certificate of Occupancy issued after Sept 2008',
  },
  {
    id: 'validBefore',
    name: 'There is a valid Certificate of Occupancy issued before Sept 2008',
  },
  {
    id: 'noValidCoo',
    name: 'There is no valid Certificate of Occupancy',
  },
];

export const VARIANT_OPTIONS: {
  [key in CertificateStateVariantsId]: VariantOption[];
} = {
  validAfter: [
    {
      id: 'change',
      label: getApplicationTypeLabelById('change'),
      description: (
        <>
          <p> Select Change if you are applying due to:</p>
          <ul>
            <li>
              <strong>a change of owner or business of the property,</strong>
            </li>
            <li>
              <strong>an occupancy load change</strong> (increasing or decreasing the number of allowed occupants),
            </li>
            <li>
              <strong>a change in use</strong> (for example change from single family home to family flat).
            </li>
          </ul>
        </>
      ),
    },
    // {
    //   id: "revision",
    //   label: "Revision",
    //   description: (
    //     <>
    //       <p>Select Revision if there is:</p>
    //       <ul>
    //         <li>
    //           <strong>a typo in the text of the certificate</strong>
    //         </li>
    //         <li>
    //           <strong>
    //             an error with the listed Business name or owner name.
    //           </strong>
    //         </li>
    //       </ul>
    //     </>
    //   ),
    // },
  ],
  validBefore: [
    {
      id: 'change',
      label: getApplicationTypeLabelById('change'),
      description: (
        <>
          <p> Select Change if you are applying due to:</p>
          <ul>
            <li>
              <strong>a change of owner or business of the property,</strong>
            </li>
            <li>
              <strong>an occupancy load change</strong> (increasing or decreasing the number of allowed occupants),
            </li>
            <li>
              <strong>a change in use</strong> (for example change from single family home to family flat).
            </li>
          </ul>
        </>
      ),
    },
    // {
    //   id: "revision",
    //   label: "Revision",
    //   description: (
    //     <>
    //       <p>Select Revision if there is:</p>
    //       <ul>
    //         <li>
    //           <strong>a typo in the text of the certificate</strong>
    //         </li>
    //         <li>
    //           <strong>
    //             an error with the listed Business name or owner name.
    //           </strong>
    //         </li>
    //       </ul>
    //     </>
    //   ),
    // },
  ],
  noValidCoo: [
    {
      id: 'newConstruction',
      label: getApplicationTypeLabelById('newConstruction'),
      description: (
        <>
          <p>
            No previous building or structure or new tenant, buildout of floor, unrecognized space that was never
            documented.
          </p>
          <p>
            If seeking a Core and Shell and Conditional CofO, you must complete two separate CofO applications beginning
            with the Core and shell application first. When you apply for the Conditional, you will be asked to provide
            the Core and Shell number.
          </p>
        </>
      ),
    },
    {
      id: 'temporarySeasonal',
      label: getApplicationTypeLabelById('temporarySeasonal'),
      description: (
        <>
          <p>
            The use or occupancy of a space is temporary and for a limited period of time to not exceed (12) twelve
            months (for example Farmer's Market, Christmas Tree Stand, Ice Skating Rink, PUPs, etc.).
          </p>
        </>
      ),
    },
    {
      id: 'twoFamilyFlat',
      label: getApplicationTypeLabelById('twoFamilyFlat'),
      description: (
        <>
          <p>
            An existing flat (two dwelling units) without a previous certificate of occupancy and no new construction
            only. <br />
            By choosing this <strong>TWO-FAMILY FLAT</strong> option the review time is extended due to the lack of
            permits.
          </p>
          <p>
            If the structure was converted to a two-family flat (two dwelling units) with a building permit, apply for a
            certificate of occupancy using the <strong>NEW CONSTRUCTION/ ESTABLISHMENT</strong> option.
          </p>
        </>
      ),
    },
  ],
};
