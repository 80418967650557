import { Address } from 'types';

import { Box } from '@mui/material';

export interface AddressFormattedProps {
  address: Address;
  commaSeparated?: boolean;
  isUppercase?: boolean;
  isInline?: boolean;
  isWithCity?: boolean;
}

export default function AddressFormatted(props: AddressFormattedProps) {
  const { address, commaSeparated = true, isUppercase = false, isInline = false, isWithCity = true } = props;

  const streetNumber = address.streetNumber || '';
  const unitNumber = address.unitNumber || '';
  const zip = address.zip || '';

  const firstLineAddress = `
      ${streetNumber}
      ${address.streetNumberFraction || ''}
      ${address.streetPrefix || ''}
      ${address.streetName || ''} ${address.streetNameSuffix || ''}
      ${address.quadrant}`;

  const secondLineAddress = unitNumber && `UNIT ${unitNumber}`;
  const thirdLineAddress = `
    ${isWithCity ? address.city || 'WASHINGTON' : ''}
    ${isWithCity ? address.state || 'DC' : ''}
    ${zip || ''}`;

  return (
    <Box display={isInline ? 'inline' : 'block'} sx={isUppercase ? { textTransform: 'uppercase' } : {}}>
      {!commaSeparated && (
        <>
          {firstLineAddress}
          <br />
          {secondLineAddress && (
            <>
              {secondLineAddress}
              <br />
            </>
          )}
          {thirdLineAddress}
        </>
      )}
      {commaSeparated && (
        <>
          {firstLineAddress}
          {(secondLineAddress.trim() || thirdLineAddress.trim()) && ', '}
          {secondLineAddress.trim() && (
            <>
              {secondLineAddress}
              {thirdLineAddress.trim() && ', '}
            </>
          )}
          {thirdLineAddress}
        </>
      )}
    </Box>
  );
}
