import { getLinkToDetails, getLinkToStep } from 'app-sections/user/applications/steps/config';
import { getStepFromPath } from 'helpers/getStepFromPath';
import { Location } from 'react-router-dom';
import { Application, UserData } from 'types';
import { isAdmin } from 'ui/helpers/users';

const getPathParts = (path: string): string[] => {
  return path.split('/');
};

export const applicationsResolverGuards = (
  data: Application,
  location: Location,
  currentUser: UserData | null
): string => {
  const canUserViewResult = canUserViewApp(data, currentUser);
  if (canUserViewResult !== '') {
    return canUserViewResult;
  }

  const pathParts = getPathParts(location.pathname);
  const result = canBeOnCurrentSection(data, location);
  if (result !== '') {
    return result;
  }

  if (pathParts.includes('step')) {
    return canBeOnCurrentStepGuard(data, location);
  }

  return '';
};

export const canBeOnCurrentStepGuard = (data: Application, location: Location): string => {
  const step = getStepFromPath(location.pathname);
  if (step > data.currentStep + 1) {
    console.error('Application not allowed to be on this step.');
    return getLinkToStep(data.id, data.currentStep + 1);
  }
  return '';
};

export const canBeOnCurrentSection = (data: Application, location: Location): string => {
  const pathParts = getPathParts(location.pathname);

  if (pathParts.includes('details') && data.state === 'draft') {
    console.error('Application not allowed to be on section.');
    return getLinkToStep(data.id, data.currentStep + 1);
  }

  if (pathParts.includes('step') && data.state !== 'draft') {
    console.error('Application not allowed to be on section.');
    return getLinkToDetails(data.id, 'status-info');
  }

  return '';
};

export const canUserViewApp = (data: Application, currentUser: UserData | null): string => {
  if (!currentUser) {
    return '/';
  }
  // Admin can always view the Application
  if (!isAdmin(currentUser.userProfile.roles)) {
    if (data?.clientId !== currentUser.id) {
      return '/applications';
    }
  }

  return '';
};

const PAYMENT_ALLOWED_STATES = ['pending_payment'];

export const applicationsPaymentResolverGuards = (data: Application, currentUser: UserData | null): string => {
  if (!currentUser) {
    return '/';
  }

  // only User can get to the payment page
  if (isAdmin(currentUser.userProfile.roles)) {
    return '/applications';
  }

  // only applications in states: ['pending_payment]
  if (!PAYMENT_ALLOWED_STATES.includes(data.state)) {
    return '/applications';
  }

  return '';
};
